import { useFloating, shift, flip, offset } from '@floating-ui/react';
import {
  Icon,
  Label,
  Portal,
  TOOLTIP_SPACING_FROM_SCREEN_EDGE,
  TriggerButton,
  useClickOutside,
  useOnKeyEvent,
  DOMInteractionEvent,
  Key,
  IconProps,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { SxStyleProp } from 'theme-ui';

import { Flights, FlightsProps } from './Flights';
import { ClassNameProps } from '@ComponentProps';
import { trackEvent } from '@Core/tracking/hooks/useInteractionTracking';
import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent } from '@Core/tracking/types';
import { useBreakpoint } from '@Core/useBreakpoint';

interface FlightsIncludedLabelProps extends FlightsProps, ClassNameProps {
  labelStyles?: SxStyleProp;
  iconSizes?: IconProps['size'];
}

const SPACING_BETWEEN_TRIGGER_AND_TOOLTIP = 32;

// @TODO: this is not the best solution as IDs will be re-generated during hydration.
// https://github.com/preactjs/preact/issues/3373
const generateId = () => `tooltip-${Math.random().toString(36).slice(2, 9)}`;

const gridStyles: SxStyleProp = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: ['auto 1fr auto', 'none'],
  gridAutoFlow: 'column',
};

export const FlightsIncludedLabel: React.FC<FlightsIncludedLabelProps> = ({
  className,
  labelStyles,
  iconSizes = ['20', '16'],
  ...flightProps
}) => {
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation();

  const {
    x,
    y,
    reference: setTriggerRef,
    floating: setTooltipRef,
    strategy,
    update,
    refs: { reference: triggerRef, floating: tooltipRef },
  } = useFloating({
    placement: 'bottom',
    middleware: [
      offset(SPACING_BETWEEN_TRIGGER_AND_TOOLTIP),
      flip(),
      shift({ padding: TOOLTIP_SPACING_FROM_SCREEN_EDGE }),
    ],
  });

  const [id] = useState(generateId);
  const [isOpened, setIsOpened] = useState(false);
  const hasData = !!flightProps.outbound?.segments;

  useEffect(() => {
    if (!hasData) {
      sendEvent({
        event: TrackingEvent.webEvent,
        action: 'missingData',
        label: 'FlightIncludedLabel',
      });
    }
  }, [hasData]);

  const close = useCallback(() => {
    if (isOpened) {
      setIsOpened(false);
      trackEvent(DOMInteractionEvent.CLICK, 'flightsIncluded', 'close');
    }
  }, [isOpened]);

  const toggle = useCallback(() => {
    if (isOpened) {
      close();
    } else {
      setIsOpened(true);
      update();
      trackEvent(DOMInteractionEvent.CLICK, 'flights-included', 'open');
    }
  }, [close, isOpened, update, setIsOpened]);

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useClickOutside({
      ref: [triggerRef as React.MutableRefObject<HTMLElement | null>, tooltipRef],
      isActive: isOpened && !isMobile(),
      onClick: close,
    });
  }

  useOnKeyEvent(Key.Escape, close, [close]);

  const label = (
    <Fragment>
      <Icon
        name="Content/PlaceAirport"
        size={iconSizes}
        sx={{
          marginRight: '3xs',
        }}
      />
      <span>{t('flightsIncluded')}</span>
      {hasData && (
        <Icon
          name={['Actions/Expand', 'Toggles/ExpandOn']}
          size={iconSizes}
          sx={{
            marginLeft: '4xs',
          }}
        />
      )}
    </Fragment>
  );

  return (
    <Label
      variant="small"
      data-id="summary-flights"
      sx={hasData ? {} : gridStyles}
      className={className}
    >
      {hasData ? (
        <TriggerButton
          onTrigger={toggle}
          ref={setTriggerRef}
          aria-describedby={id}
          sx={{
            ...gridStyles,
            ...labelStyles,
          }}
        >
          {label}
        </TriggerButton>
      ) : (
        label
      )}
      {isOpened &&
        (isMobile() ? (
          <Flights
            {...flightProps}
            sx={{
              marginTop: 'l',
            }}
          />
        ) : (
          <Portal>
            <Flights
              id={id}
              ref={setTooltipRef}
              sx={{
                position: strategy,
                zIndex: 'tooltip',
                top: y,
                left: x,
                borderRadius: '12',
                backgroundColor: 'backgroundWhite',
                boxShadow: (t) => t.shadows.elevationFixed,
                padding: 'xs',
                minWidth: 322,
              }}
              {...flightProps}
            />
          </Portal>
        ))}
    </Label>
  );
};
