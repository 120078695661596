import { useTranslation } from '@loveholidays/phrasebook';
import React, { forwardRef } from 'react';

import { OfferFlights } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { FlightDetails, FlightDetailsProps } from '@Components/FlightDetails/FlightDetails';
import { useProductClickTracking } from '@Tracking';

export interface FlightsProps {
  alternativeFlightsUrl?: string;
  onAlternativeFlightsClick?: () => void;
  isOpenJaw: OfferFlights['isOpenJaw'];
  outbound: FlightDetailsProps['leg'];
  inbound: FlightDetailsProps['leg'];
  hotelName: string;
  hotelMasterId: string;
}

export const Flights = forwardRef<HTMLDivElement, FlightsProps & ClassNameProps>(
  (
    {
      alternativeFlightsUrl,
      onAlternativeFlightsClick,
      isOpenJaw,
      outbound,
      inbound,
      className,
      id,
      hotelName,
      hotelMasterId,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const alternativeFlightsTracking = useProductClickTracking();

    return (
      <div
        ref={ref}
        id={id}
        className={className}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FlightDetails
          leg={outbound}
          icon="Markers/FlightsOut"
          variant="small"
          expandStops={false}
          direction="outbound"
        />
        <FlightDetails
          sx={{
            marginY: 'xs',
            borderColor: 'flightCardBorder',
            borderTopWidth: 1,
            borderStyle: 'solid',
            paddingTop: 'xs',
          }}
          leg={inbound}
          icon="Markers/FlightsIn"
          isOpenJaw={isOpenJaw}
          variant="small"
          direction="inbound"
          expandStops={false}
        />
        {alternativeFlightsUrl && (
          <Button
            variant="Secondary"
            size="36"
            href={alternativeFlightsUrl}
            data-id="alt-flights-tooltip-button"
            asAnchor={false}
            onClick={() => {
              alternativeFlightsTracking([
                {
                  name: hotelName,
                  masterId: hotelMasterId,
                  ref: 'srp-to-alternative-flights',
                },
              ]);
              onAlternativeFlightsClick?.();
            }}
          >
            {t('viewAlternativeFlights')}
          </Button>
        )}
      </div>
    );
  },
);
